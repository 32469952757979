import { Content, ContentData, InviteAction } from "../shared";
import "./FirstAid.scss";



const content: ContentData[] = [
    {title: 'Comprehensive Curriculum:', summary: "Our courses cover a wide range of Adult and Pediatric first aid topics, including basic first aid techniques, CPR (Cardiopulmonary Resuscitation), and AED (Automated External Defibrillator) usage."},
    {title: 'Certified Instructors:', summary: "Learn from experienced and certified instructors who bring real-world expertise to the classroom. Our instructors are passionate about equipping you with the confidence to handle emergencies."},
    {title: 'Interactive Learning:', summary: 'Engage in blended classes or  hands-on, scenario-based training that simulates real-life emergencies. This interactive approach ensures that participants can apply their knowledge in practical situations.'},
    {title: 'Customized Training Packages:', summary: 'We offer flexibility to meet the unique needs of individuals and organizations. Choose from a variety of courses or inquire about customized training packages for groups.'},
    {title: 'Certification for Your Peace of Mind:', summary: 'Upon successful completion of our First Aid Classes, participants receive certifications that are widely recognized. These certifications not only build confidence but also demonstrate a commitment to safety.'},
    {title: 'On-Site Training for Businesses:', summary: 'For corporate clients, we provide on-site training tailored to your workplace needs. Empower your team with the skills to respond to emergencies and create a safer work environment.'},
]





export const FirstAid = () => {
    return (
        <div className="first-aid">
            <Content title="Key Features" data={content} />

            <InviteAction />
        </div>
    )
}

import { Children, cloneElement, isValidElement } from "react";
import "./PatternBackground.scss";

interface Props {
    children: any;
}
export const PatternBackground: React.FC<Props> = ({children}) => {
    // const hasImage = useRef(bgImg.length > 0).current;
    const content = Children.map(children, (child: React.ReactElement) => {
        if (isValidElement(child)) {
          return cloneElement<any>(child, {
            className: `${(child.props as any).className} content`
          } as Partial<React.HTMLAttributes<HTMLElement>>);
        }
        return child;
      });
    
    return (
        <div className="bg-container">
           {content}
        </div>
    )
}
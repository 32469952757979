import { useRef, useState } from "react";
import { useIntervalInView } from "../custom_hooks";
import "./AwCarousel.scss";

export interface ISlide {
    imageUrl: string;
    caption: string;
}



  interface Props {
    pgName: string;
    slides: ISlide[];
  }
export const AwCarousel: React.FC<Props> = ({ pgName, slides }) => {
    const ref = useRef<any>();
    const [activeSlide, setActiveSlide] = useState(0);
  
    const goToPreviousSlide = () => {
      setActiveSlide((prevSlide) => (prevSlide === 0 ? slides.length - 1 : prevSlide - 1));
    };
  
    const goToNextSlide = () => {
      setActiveSlide((prevSlide) => (prevSlide === slides.length - 1 ? 0 : prevSlide + 1));
    };
    useIntervalInView(ref, goToNextSlide, 10000);
    return (
      <div className="carousel" ref={ref}>
        {/* <button onClick={goToPreviousSlide}>Previous</button> */}
        {/* <div className="slide">
          <img src={slides[activeSlide].imageUrl} alt={slides[activeSlide].caption} />
          <p>{slides[activeSlide].caption}</p>
        </div> */}
        {slides.map((slide, index) => (
        <div
          key={index}
          className={`slide ${pgName} ${index === activeSlide ? 'active' : ''}`}
          style={{ backgroundImage: `url(${slide.imageUrl})` }}
        >
          {/* <p>{slide.caption}</p> */}
        </div>
      ))}
        {/* <button onClick={goToNextSlide}>Next</button> */}
      </div>
    );
  };

  
  
  
  

import { FixedContainer } from '../../../pages/page_components';
import { IMenu, menus } from '../../menus';
import { NavLink } from 'react-router-dom';
import './QuickLinks.scss';

export const QuickLinks = () => {
 return (
    <FixedContainer >
        <div className="quick-links" style={{paddingTop: '2rem', paddingBottom: '2rem'}}>
        <div className="footer-nav-links">
                <h1>Quick Links</h1>
            <div className="f-n-lks">
                <div className="links">
                    {menus.map((menu: IMenu, i) => {
                        if(menu.route) {
                            return (
                                <div key={i} className='footer-nav-link'>
                                    <NavLink to={menu.route}><span>{menu.name}</span></NavLink>
                                </div>
                            )
                        } else {
                            return (menu.children as IMenu[]).map((chn, idx) => {
                                return (
                                    <div key={idx} className='footer-nav-link'>
                                    <NavLink to={chn.route}><span>{chn.name}</span></NavLink>
                                    </div>
                                )
                            })
                        }
                    })}
                </div>
                {/* <div style={{minWidth: "300px"}}>

                </div> */}
                {/* <div className='qrcode-section'>
                    <h2>Donation Qr code</h2>
                    <div>
                        <img src={donateqrcode} alt='donate qr code'/>
                    </div>
                </div> */}
            </div>
            {/* links end here */}
        </div>
        {/* <div className="footer-social-media-links">

        </div> */}
        </div>
        
    </FixedContainer>
 );
}
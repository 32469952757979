import { FbIcon, InstagramIcon, TwitterIcon } from "../../../assets";
import { externalLinks } from "./externalLinks";
import './SocialMediaLinks.scss';


export const SocialMediaLinks = () => {
    return (
       <div className="social-media-links">
          <div className='content'>
               <p>Connect <span>with us</span> </p>
               <div>
                   <a href={externalLinks.facebook} 
                       target="_blank" 
                       className="sm-icon icon-facebook" 
                       rel="noopener">
                       <FbIcon />
                   </a> 
                   {/* <a href={externalLinks.twitter} 
                       target="_blank" 
                       className="sm-icon icon-twitter" 
                       rel="noopener">
                       <TwitterIcon />
                   </a>  */}
                   <a href={externalLinks.instagram}
                       target="_blank" 
                       className="sm-icon icon-instagram" 
                       rel="noopener">
                       <InstagramIcon />
                   </a>
               </div>
          </div>
       </div>
    );
   }
import { awEncodeUrl } from "../../utils";

export interface IMenu {
    route: string;
    name: string;
    children?: IMenu[];
}

export const menuData = {
    landing: {route: '/', name: 'Landing'},
    home: {route: '/home', name: 'Home'},
    // programs: {route: '/programs', name: 'Programs'},
    // resources: {route: '/resources', name: 'Resources'},
    ourservices: {route: '/ourservices', name: 'Our Services'},
    help: {route: '/contactus', name: 'Contact Us'},
    faq: {route: 'faq', name: 'Faq'},
    contact: {route: '/contact', name: 'Contact Us'},
    about: {route: '/about', name: 'About Us'}
}

export const menuDetails = {
    serviceLink: (name: string) => `/ourservices/${awEncodeUrl(name)}`.toLowerCase(),
    serviceDetail: {route: '/ourservices/:serviceType', name: 'Our Services'},
    serviceDetailExample: {route: '/ourservices/:serviceType/:type', name: 'Our Services'}
}


export const menus: IMenu[] = [
    menuData.home, 
    // menuData.programs, 
    // menuData.resources,
    menuData.ourservices,
    menuData.about,
    // menuData.help,
    menuData.contact,
];


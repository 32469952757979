import { NavLink } from "react-router-dom";
// import logo from "../../../assets/logos/logo.jpg";
// import logo from "../../../assets/logos/full_hd_svg_logo.svg";
import logo from "../../../assets/logos/transparent_wellness.png";
// import logo from "../../../assets/logos/hd_tranaparent_logo.png";

import { menuData } from "../../menus";
import "./LogoArea.scss";

export const LogoArea = () => {
    return (
        <div className="logo-area">
            <NavLink to={menuData.landing.route}>
                <img src={logo} alt="" />
                {/* <img src={logo}  alt=""  /> */}
            </NavLink>
        </div>
    )
}
import { useState } from 'react';
import { IObject, splitCamelCaseWordToCommaSeparatedString } from '../../../utils';
import './Field.scss'
import { DateField, DateTimeField, DropdownField } from './special';
import { NumberInput, Password, PhoneInput, RadioInput, UploadInput } from './types';
import { DateInput, DateTimeInput } from './types/date';
import { IField, IInputResponse, IInputValue, fieldTypes } from '../FormHelpers';

type ITxtAreaEvt = React.FormEvent<HTMLTextAreaElement>;
type IEvt = React.FormEvent<HTMLInputElement>;


export interface IFieldProps {
    field: IField;
    changeHandler: (x: IInputResponse, resetField: () => void) => void;
    formName: string;
    initObj?: IObject;
}


export const Field: React.FC<IFieldProps> = ({field, formName, initObj = {}, changeHandler }) => {
    const [value, setValue] = useState(Object.keys(initObj).length > 0 ? initObj[field.name] : '');

    const fieldName = field.name;
    const fieldId = `${formName}_${fieldName}`.toLowerCase();
    const label = field.label ||  splitCamelCaseWordToCommaSeparatedString(fieldName);
    
    const handler = (e: any) => {
        const val = e.currentTarget.value;
        setValue(val);
        valueHandler({name: fieldName, value: val}, resetValue);
    }

    const resetValue = () => {
        setValue('');
    }

    const valueHandler = (v: IInputValue, callback: () => void) => {
        changeHandler(v, callback);
    }


    let input: any;
    
    switch(field.type) {
        
        case fieldTypes.number:
            input = <NumberInput id={fieldId} init={value} name={fieldName}  onChange={valueHandler}/>;
            break;
        case fieldTypes.phone:
            input = <PhoneInput init={value} id={fieldId} name={fieldName}  onChange={valueHandler}/>;
            break;
        case fieldTypes.textarea:
            input = <textarea id={fieldId} name={fieldName} value={value} onChange={handler} />;
            break;
        case fieldTypes.password:
            input = <Password id={fieldId} hidePassword={field.hidePassword || false } name={fieldName} value={value} onChange={handler} />
            break;
        case fieldTypes.datetime:
            input = <DateTimeInput init={value} id={fieldId} name={fieldName} onChange={valueHandler} />
            break;
        case fieldTypes.date:
            input = <DateInput init={value} id={fieldId} name={fieldName} onChange={valueHandler} />
            break;
        case fieldTypes.file:
            input = <UploadInput name={fieldName} onSelect={valueHandler} />
            break;
        default:
            input = <input id={fieldId} name={fieldName} value={value} onChange={handler} />;
    }

    return(
        field.type === fieldTypes.dropdown ? <DropdownField options={(field.dropdown as any).options || []} field={field} formName={formName} changeHandler={valueHandler} /> :
        field.type === fieldTypes.radio ? <RadioInput name={fieldName} label={label} onChange={handler} /> :
        field.type === fieldTypes.datetime ? <DateTimeField initObj={initObj} field={field} label={label} changeHandler={valueHandler} id={fieldId} formName={''} /> :
        field.type === fieldTypes.date ? <DateField initObj={initObj} field={field} label={label} changeHandler={valueHandler} id={fieldId} formName={''} /> :

       ( 
            <div className="field text-field">
                <label htmlFor={fieldId}>
                    {label}  {field.required && <small>*</small>}
                    {['name', 'Name'].includes(label) ? <>
                <small style={{color: '#ccc', fontSize: '0.6rem' }}>(First & Last Name)</small>
                </> : <></> }
                </label>
                {input}
            </div>
        )
        
    )
}




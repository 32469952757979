import { AwLink } from "../../../../components";
import { menuData } from "../../../../components/menus";
import { Content, ContentData } from "../shared"

import "./CorporateGame.scss";


const data: ContentData[] = [
    {title: "Tailored Team-Building Games / employees retreat:", summary: "Experience customized team-building games that are tailored to your company's goals and objectives. From problem-solving challenges to strategic competitions, our games are crafted to enhance teamwork and communication."},
    {title: "Engaging Activities:", summary: "Enjoy a variety of engaging activities that promote camaraderie and team spirit. Our Corporate Game Day is filled with interactive experiences that cater to all preferences, ensuring everyone can participate and have a blast."},
    {title: "Professional Facilitation:", summary: "Our experienced facilitators are experts in creating a positive and inclusive atmosphere. They guide your team through the activities, providing insightful debriefs to reinforce key takeaways and foster a positive learning environment."},
    {title: "Flexible Formats:", summary: "Choose from a range of game formats, including indoor and outdoor options. Whether you prefer a day of adventure outdoors or a dynamic indoor setting, we have the flexibility to accommodate your preferences."},
    {title: "Scalable for Any Group Size:", summary: "Our Corporate Game Day experiences are scalable, making them suitable for small teams or large departments. No matter the size of your group, we ensure a seamless and enjoyable experience for everyone involved."},
    {title: "Catering Options:", summary: "Elevate your Corporate Game Day with catering options to keep your team energized and fueled throughout the activities. Choose from a variety of menus to suit your team's preferences."},
    {title: "Post-Event Networking:", summary: "Foster connections and camaraderie beyond the games with post-event networking opportunities. Our Corporate Game Day is not just about the activities; it's also a chance for your team to relax, unwind, and build lasting connections."},
    // {title: "", summary: ""},
];




export const CorporateGame = () => {
    return (
        <div className="corporate-game">
            <Content title="Key Features" data={data} />
            {/* <Content title="Why Choose Us" data={reasons} /> */}
            <div className="invite-action-container">
                <p>
                Ready to elevate your corporate team-building experience? <AwLink label="Contact us" to={menuData.contact.route}/> today to discuss how  we can make your next Corporate Game Day or employees retreat an unforgettable success!
                </p>
            </div>
        </div>
    )
}
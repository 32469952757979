import { Children, cloneElement, isValidElement } from "react";
import "./ImageBackground.scss";
interface Props {
    bgImg: string;
    // banner?: Component;
    children: any;
}
export const ImageBackground: React.FC<Props> = ({bgImg, children}) => {
    // const hasImage = useRef(bgImg.length > 0).current;
    const content = Children.map(children, (child: React.ReactElement) => {
        if (isValidElement(child)) {
          return cloneElement<any>(child, {
            className: `${(child.props as any).className} content`
          } as Partial<React.HTMLAttributes<HTMLElement>>);
        }
        return child;
      });
    
    return (
        <div className="img-bg-container" style={{backgroundImage: `url(${bgImg})`}}>
           <img className="sm-scrn-img" src={bgImg} />
           {content}
        </div>
    )
}
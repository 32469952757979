import emailjs, { EmailJSResponseStatus } from "@emailjs/browser";



export interface IObject {
    [x: string]: any;
}
export function debounceFxn(func: any, delay: any) {
    let timeoutId: any;
  
    return function (...args: any[]) {
      clearTimeout(timeoutId);
      timeoutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  }

export function shortenString(str = "", maxLength = 200) {
    if (str.length <= maxLength) {
      return str;
    } else {
      return str.substring(0, maxLength - 3) + '...';
    }
  }
  
export function awEncodeUrl(service: string) {
    if(!service) return '';
    return  service.replace(/ /g, '_');
}

export function awDecodeUrl(service: string) {
    if(!service) return '';
    // service.replace(/_/g, ' ');
    return  service.replace(/_/g, ' ').replace(/\b\w/g, (char) => char.toUpperCase());
}

export function padTo2Digits(num: any) {
    return num.toString().padStart(2, '0');
  }

function convertToDate(dateOrTimestamp: Date | any): Date {
    // console.log(dateOrTimestamp);
    if(dateOrTimestamp.seconds) {
        // this is for timestamps
        let seconds = dateOrTimestamp?.seconds * 1000;
        if(dateOrTimestamp.nanoseconds) {
            // adding nanoseconds to seconds
            const nanoseconds = (dateOrTimestamp?.nanoseconds / (1000*1000*1000));
            seconds = (seconds + nanoseconds);
        }
        // console.log(new Date(seconds).toDateString());
        return new Date(seconds);//.toDateString()
    } else {
        return new Date(dateOrTimestamp);
    }
}

export function formatDate(d: Date) {
    const date = convertToDate(d);
    return [
        padTo2Digits(date.getMonth() + 1),
      padTo2Digits(date.getDate()),
      date.getFullYear(),
    ].join('/');
}


export interface IEmailForm {
    user_name: string;
    user_info: string;
    subject: string;
    message: string;
    payment_method?: string;
}
const sendEmail = async (funcAsync: any, values: any) => {
    try {
        const res: EmailJSResponseStatus =  await funcAsync(
            `${process.env.REACT_APP_CONTACT_EMAIL_SERVICE}`, 
            `${process.env.REACT_APP_CONTACT_EMAIL_TEMPLATE_SERVICE}`, 
            values,
            `${process.env.REACT_APP_EMAIL_SERVICE_PUBLIC_KEY}`);
            
        if(res.status < 400) {
            return {success: true, message: ''}
        } else {
            return {success: false, message: 'Email sending failure! Please try again later!'}
        }
    } catch (error: any) {
        return {success: false, message: error || 'Email sending failure! Please try again later!'}
    }
};

export const sendFormEmailAsync = async (form: HTMLFormElement) => { 
    return await sendEmail(emailjs.sendForm, form);
}
export const sendEmailAsync = async (values: IEmailForm) => {
    // form.current
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', (values as any), 'YOUR_PUBLIC_KEY')
    // .then((result) => {
    //     console.log(result.text);
    // }, (error) => {
    //     console.log(error.text);
    // });
    return await sendEmail(emailjs.send, values);
};


interface IPosition<T> {
    beforeAnEle: T;
    firstChildOfAnEle: T;
    lastChildOfAnEle: T;
    afterAnEle: T;
}
export const insertHTMLPositions: IPosition<any> = {
    beforeAnEle: 'beforebegin',
    firstChildOfAnEle: 'afterbegin',
    lastChildOfAnEle: 'beforeend',
    afterAnEle: 'afterend'
}
export const splitCamelCaseWordToCommaSeparatedString = function(str: string) {
    return str
        .split(/(?=[A-Z])/)
        .map((p: any) => {
        return p[0].toUpperCase() + p.slice(1);
        })
        .join(' ');
    }

export const setExtensionMethods = () => {

    const type = (String.prototype as any);

    type.isEmpty = function () {
        return this.length === 0;
    };

    type.normalize = function() {
        return this.split(' ').join('').toLowerCase();
    }

    type.lowerCase = function() {
        return this.toLowerCase();
    }


    type.capitalizeFirstLetter = function() {
        if (this.isEmpty()) return '';
        return `${this[0].toUpperCase()}${this.slice(1).toLowerCase()}`
    }

    type.splitCamelCaseWordToCommaSeparatedString = function() {
        return this
            .split(/(?=[A-Z])/)
            .map((p: any) => {
            return p[0].toUpperCase() + p.slice(1);
            })
            .join(' ');
    }

    type.phone = function() {
        if(this.isEmpty()) return '';
        if(this.length === 3 || this.length < 4) return this;
        let phone = this;
        phone = phone.replace(/\D/g, '');
        const match = phone.match(/^(\d{1,3})(\d{0,3})(\d{0,4})$/);
        if (match) {
            phone = `(${match[1]}${match[2] ? '' : ''}) ${match[2]}${match[3] ? '-' : ''}${match[3]}`;
        }
        
        return phone
    }

    // String to numbers
    type.int = function() {
        return parseInt(this, 10) // +this;
    }
    type.float = function() {
        return parseFloat(this); // +this
    }

    type.number = function() {
        return +this;
    }

    // Number type
    const numType = (Number as any)

    numType.string = function(): string {
        return this.toString();
    }
}

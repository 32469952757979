import { Outlet, useLocation } from "react-router-dom"
import { WithFooter } from "../../pages/page_components"
import { Footer } from "../footer"
import { Header } from "../header"
import { useEffect } from "react"

export const RootLayout = () => {
    useEffect(() => {
        document.documentElement.scrollTo({
            top: 0,
            left: 0,
            // behavior: "instant", // Optional if you want to skip the scrolling animation
          });
      }, []);
    return (
        <WithFooter className="root-layout" style={{overflowX: 'hidden', minHeight: "100vh", display: 'flex', flexDirection: "column"}}>
            <Header />
            <main style={{flexGrow: 1}}>
                <ScrollToTop>
                    <Outlet />
                </ScrollToTop>
            </main>
        </WithFooter>
    )
}

interface Props {
    children?: any;
}
const ScrollToTop: React.FC<Props> = ({ children }) => {
    const { pathname } = useLocation();
    
    useEffect(() => {
      const canControlScrollRestoration = 'scrollRestoration' in window.history
      if (canControlScrollRestoration) {
        window.history.scrollRestoration = 'manual';
      }
      window.scrollTo(0, 0);
    }, [pathname]);
    
    return children;
  }
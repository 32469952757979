import { createContext, useContext, useEffect, useState } from "react";


interface IObject {
    [x: string]: any
}
interface IMobileMenuState {
    isMenuShowing: boolean;
    hideMenu: () => void;
    showMenu: () => void;
}
interface IMobile extends IMobileMenuState {
}

export const notificationTypes = {
    error: 'error',
    success: 'success',
    info: 'info',
    warning: 'warning'
}
export const notificationLocations = {
    main: 'main',
    profile: 'profile'
}
export interface INotification {
    isNotify: boolean;
    type: string;
    msg: string;
    timeout: number;
    canClose: boolean;
    location: string;
    link: any;
}
export interface INullableNotification {
    isNotify?: boolean;
    type?: string;
    msg?: string;
    timeout?: number;
    canClose?: boolean;
    location?: string;
    link?: any;
}

interface IPage {
    scroll: {y: number; x: number; setScroll: (v: IObject) => void;}
}

interface IAppSettings {
    handleNotification: (v: INullableNotification) => void;
    loading: boolean;
    handleLoading:  (v: boolean) => void;
}



export const AppContext = createContext<IAppSettings>({} as IAppSettings);

export const AppProvider = ({...props}) => {
    const {children} = props;

    // const mobileMenUpdate = (v: boolean) => {
    //     updateSettings(state => ({...state, mobile: {...state.mobile, isMenuShowing: v}}));
    // }
    // const setScroll = (x: IObject) => {
    //     updateSettings(state => ({...state, page: {...state.page, scroll: {...state.page.scroll, ...x}}}));
    // }
    // const showMobileMenu = () => mobileMenUpdate(true);
    // const hideMobileMenu = () => mobileMenUpdate(false);

    const [notification, setNotification] = useState<INotification>(
        {isNotify: false, type: notificationTypes.info, msg: '', canClose: true, timeout: 5000, location: notificationLocations.main, link: ''}
    );
    const [loading, setLoading] = useState(false);
    
    const handleNotification = (v: INullableNotification) => {
        setNotification(state => ({...state, location: notificationLocations.main, link: null, isNotify: true, ...v}));
    }
    
    const handleLoading = (v: boolean) => {
        setLoading(v);
    }

    
    return (
        <AppContext.Provider value={{ 
            handleNotification, 
            loading, 
            handleLoading }}>
            {children}
        </AppContext.Provider>
    )
}

import React, { useEffect, useRef } from 'react';
import { IObject } from '../../../../../utils';
import { useOutsideClick } from '../../../../custom_hooks';
import './Dropdown.scss'
import { useDropdownFuncs } from './useDropdownFuncs';

interface Props {
    vKey: string; // value key
    dKey: string; // display key
    list: IObject[];
    onSelected: (v: any) => void;
    shouldPreselect?: boolean;
    shouldSelectValue?: boolean;
}
export const Dropdown: React.FC<Props> = ({
    vKey, 
    dKey, 
    list = [], 
    onSelected, 
    shouldPreselect = true, 
    shouldSelectValue = true}) => {
        
    const dRef = useRef<any>();
    const { onSelectClicked, selectedItem, onItemClicked} = useDropdownFuncs({
        ref: dRef, 
        onSelected: onSelected, 
        list: list,
        dKey,
        vKey, 
        shouldSelectValue: shouldSelectValue, 
        preselect: shouldPreselect});

    const { isOutsideClick } = useOutsideClick(dRef)
    
    useEffect(() => {
        const openList = dRef.current.querySelector('.lists-open');
        if(isOutsideClick && openList) {
            onSelectClicked({});
        }
    }, [isOutsideClick])
    
    return (
        <div className="dropdown" ref={dRef}>
            <div onClick={onSelectClicked} className="select">
                <span className="selected">
                    {shouldPreselect ? selectedItem[dKey] : 'Select one'}
                </span>
                <div className="caret"></div>
            </div>
            <ul className="lists">
                {list.map((item, idx) => {
                    return (
                        <li onClick={onItemClicked} className={`${selectedItem[dKey] === item[dKey] ? 'active' : ''}`} key={idx}>{item[dKey]}</li>
                    )
                })}
            </ul>
        </div>
    );
}
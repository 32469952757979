import { FixedContainer } from "../../pages/page_components"
import { SocialMediaLinks } from "./external_links/SocialMediaLinks"
import { QuickLinks } from "./quick-links"

export const Footer = () => {
    return (
        <div style={{background: 'white', minHeight: '70px'}}>
            {/* <div style={{height: "10px", background: 'green'}}></div> */}
            <SocialMediaLinks />
            {/* <FixedContainer> */}
                <QuickLinks />
            {/* </FixedContainer> */}
            <FixedContainer>
            <small style={{display: 'flex', justifyContent: 'center', color: '#878585', marginBottom: '2rem'}}>
                
            <p>Copyright &copy; {new Date().getFullYear()} by <span style={{color: 'black', letterSpacing: "0.1rem"}}>Lorcán</span>. All Rights Reserved.</p>
            </small>
            </FixedContainer>
        </div>
    )
}


import { IObject } from "../../utils";

export const fieldTypes = {
    text: 'text',
    password: 'password',
    email: 'email',
    number: 'number',
    phone: 'phone',
    dropdown: 'dropdown',
    date: 'date',
    file: 'file',
    datetime: 'datetime',
    textarea: 'textarea',
    radio: 'radio'
}

export interface IOption {
    name: string;
    [x: string]: any;
}
export interface IDropdown {
    dKey: string;
    vKey: string;
    options: IOption[];
}
export interface IField {
    name: string;
    type?: string;
    init?: string;
    label?: string;
    required?: boolean;
    hidePassword?: boolean;
    dropdown?: IDropdown;
}

export interface IInputResponse {
    name: string; 
    value: string;
}

export interface IInputValue {
    name: string; 
    value: any;
}
export interface ICurrentTarget {currentTarget: {name: string; value: any;}}

export interface IInput {
    id: string; 
    name: string; 
    // value: any; 
    init?: any;
    onChange: (e: IInputValue, clear: () => void) => void;
}

export interface IField {
    name: string;
    type?: string;
    init?: string;
    label?: string;
    required?: boolean;
}

export interface IInputResponse {
    name: string; 
    value: string;
}

export interface IFormSettings {
    showBtn?: boolean;
    btnLabel?: string;
    vertical?: boolean;
    clr?: string;
    canSubmit?: boolean;
}


export interface ISegmentedField {
    header: string;
    fields: IField[];
}


export interface IForm {
    formName: string;
    fields: IField[];
    segmentFields?: ISegmentedField[];
    initObj?: IObject;
    onSubmit?: (values: any, callback?: () => void) => void;
    submitted?: boolean;
    settings?: IFormSettings;
    children?: any;
}


const validateEmail = (email: string) => {
    let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');
    return regex.test(email);
}

const checkPasswordValidation = (password: string) => {
    const isWhitespace = /^(?=.*\s)/;
    if (isWhitespace.test(password)) {
      return "Password must not contain Whitespaces.";
    }


    const isContainsUppercase = /^(?=.*[A-Z])/;
    if (!isContainsUppercase.test(password)) {
      return "Password must have at least one Uppercase Character.";
    }


    const isContainsLowercase = /^(?=.*[a-z])/;
    if (!isContainsLowercase.test(password)) {
      return "Password must have at least one Lowercase Character.";
    }


    const isContainsNumber = /^(?=.*[0-9])/;
    if (!isContainsNumber.test(password)) {
      return "Password must contain at least one Digit.";
    }


    const isContainsSymbol =
      /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹])/;
    if (!isContainsSymbol.test(password)) {
      return "Password must contain at least one Special Symbol.";
    }


    const isValidLength = /^.{8,16}$/;
    if (!isValidLength.test(password)) {
      return "Password must be 8-16 Characters Long.";
    }

    return null;
}

const validatePasswords = (password: string, confirmPassword: string): any => {
    if(password !== confirmPassword) {
        return `Passwords do not match!`;
    }
    return checkPasswordValidation(password);
}
const noValue = (fieldName: string) => {
    return `Please provide a value in the (${fieldName}) field!`;
}
export const checkFormRequiredValidity = (values: {[x: string]: any;}, fields: IField[]) => {
    let output:string[] = [];
    for (let i = 0; i < fields.length; i++) {
        if(fields[i].required) {
            // if(!values[fields[i].name.toLowerCase()]) {
            const fieldValue = values[fields[i].name];
            const fieldName = fields[i].name;
            const type = fields[i].type || 'text';
            if(fields.length === 2 && 
                ((fieldName as any).lowerCase() === 'email' 
                    || (fieldName as any).lowerCase() === 'password')) {
                    if(type === 'password' && !!fields[i + 1]) {
                        const res: string | null = validatePasswords(fieldValue, values[fields[i + 1].name]);
                        if(res) {
                            output.push(res);
                            return output;
                        }
                    } else {
                        if(!fieldValue) {
                            output.push(noValue(fieldName));
                            return output;
                        }
                    }
                    
            } else if(fieldName === 'email') {
                if(!validateEmail(fieldValue)) {
                    output.push('Please provide a valid email address!');
                    return output;
                }
            } else if(fieldName === 'password') {
                const res: string | null = validatePasswords(fieldValue, values[fields[i + 1].name]);
                if(res){
                    output.push(res);
                    return output;
                }
            } else {
                if(typeof fieldValue == "boolean") {
                
                } else if(!fieldValue) {
                    output.push(noValue(fieldName));
                    return output;
                }
            }

        }
    }
    return output;
}

export const checkAccountValidity = (values: {[x: string]: any;}, fields: IField[]) => {
    let output:string[] = [];
    const passwordField = fields.find(f => (f.name as any).lowerCase() === 'password');
    const confirmPasswordField = fields.find(f => (f.name as any).lowerCase() === 'confirmpassword');

    const password = values[passwordField?.name || ''];
    const confirmPassword = values[confirmPasswordField?.name || ''];

    // console.log('password', passwordField, password)
    // console.log('confirmPassword', confirmPasswordField, confirmPassword)
    if(password !== confirmPassword) {
        output.push(`Passwords do not match!`);
        return output;
    }

    for (let i = 0; i < fields.length; i++) {
        if(fields[i].required) {
            // if(!values[fields[i].name.toLowerCase()]) {
            const fieldValue = values[fields[i].name];
            const fieldName = fields[i].name;
            if(typeof fieldValue == "boolean") {
                
            } else if(!fieldValue) {
                 output.push(`Please correct the value in (${fieldName}) field!`);
                 return output;
            }
        }
    }
    return output; 
}

export const getFormLabel = (name: any): string => {
    if(name.length < 5) return `${name[0].toUpperCase()}${name.slice(1).toLowerCase()}`;
    return name.match(/[A-Z][a-z]+[0-9]+/g).join(" ") || "";
}


    
    // export const createFormFields = (keys: string[], fieldKeyType?: IObject = {}) => {
    //     return keys.map((str, i) => {
    //         let key = { name: str, label: getFormLabel(), type: fieldTypes.text}
    //     })
    // }
    
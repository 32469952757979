import { useNavigate, useParams } from "react-router-dom"
import { ContentContainer } from "../../page_components";
import { ImageBackground, PatternBackground } from "../../../components/helpers";
import "./ServiceDetails.scss";
import { useAwParams } from "../../../components/custom_hooks";
import { AwLink } from "../../../components";
import { IService, ListOfServices } from "../allServices";
import { menuData, menuDetails } from "../../../components/menus";
import hit from "../../../assets/images/hit.jpg";


function getService(items: any[], searchKey: string) {
    return items.length > 0 ? items.filter(service => service.title.toLowerCase() === searchKey.toLowerCase())[0] : null;
}

export const ServiceDetails = () => {
    const index = useAwParams("serviceType");
    const params = useAwParams("type");

    let service: IService;
    let childService: IService | any = null;
    let page: JSX.Element;

    const hasIndex = index.length <= 3;
    if(hasIndex) {
        service = ListOfServices[+index];
        childService = getService(ListOfServices[+index].examples || [], params);
        page = childService.page!;
    } else {
        service = getService(ListOfServices, index);
        page = service.page!;
    }

    // console.log(service);
    // console.log(childService);
    // console.log(hasIndex);
    const getBackgroundImage = () => {
        if(index && service.image) {
            return service.image;
        }
        return hit;
    }
    const content = () => {
        return (<>
                {
                !hasIndex ? (
                   <>
                        {page ??  (
                                <ul>
                                    {
                                        (service as IService).examples!.map((ex, id) => {
                                            return (
                                                <li key={id}>
                                                    <AwLink to={ex.url!} label={ex.title} />
                                                </li>
                                            )
                                        })
                                    }
                                </ul>
                            )
                        }
                   </>
                ) : page ? page : 'childService'
               }
            </>)
}
    return  (
        <div className="service-details">
            {/* <PatternBackground>
                <ContentContainer>
                    <h1 style={{letterSpacing: '0.1rem'}}>{!childService ? index : params}</h1>
                </ContentContainer>
            </PatternBackground> */}
            <ImageBackground bgImg={getBackgroundImage()}>
            </ImageBackground>
            <ContentContainer>
                <div className="bread-crumps">
                    <AwLink to={menuData.ourservices.route} label={<span className="gradient-text">Our Services</span>} />
                    {childService ? <AwLink to={menuDetails.serviceLink(service.title)} label={<span className="gradient-text">{service.title}</span>} /> : <></>}
                    {childService ? <span>{childService.title}</span> : <span>{service.title}</span>}
                </div>
                {/* <h1 className="gradient-text">
                    { childService ? (childService as IService).summary : service.summary }
                </h1> */}
                <p className="summary" dangerouslySetInnerHTML={{ __html: childService ? (childService as IService).summary : service.summary }} />
                {/* <p className="summary">{ childService ? (childService as IService).summary : service.summary }</p> */}
                {!service.examples && !service.page ? null : content()}
               
            </ContentContainer>
        </div>
    )
}



{/* to={ detail ? awEncodeUrl(ourServices[+index].title) : hasList ?  menuData.ourservices.route : '' } 
                        label={<span className="gradient-text">Our Services</span>} /> */}

// const GoBackLinkButton = ({...props}) => {
//     const history = useHistory();
//     const goBack = () => {
//         history.goBack();
//     };

//     return (
//         <button onClick={goBack}>{props.label}</button>
//     )
// }
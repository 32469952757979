import { ContentContainer } from "../../../page_components";
import services from "../../../../assets/images/services.png";
import most_popular from "../../../../assets/images/popular_services.png";

import "./LandingService.scss";
import { menuData } from "../../../../components/menus";
import { useAwNavigate } from "../../../../components/custom_hooks";

export const LandingService = () => {
    const {navigate} = useAwNavigate();
    return (
        <div className="landing-service">
            <ContentContainer>
                <div className="images">
                    <div>
                        <img src={most_popular} />
                    </div>
                    <div>
                        <img src={services} />
                    </div>
                </div>
                <div className="service-btn">
                    <button  className="accent" onClick={() => navigate(menuData.ourservices.route)}><span>Read More</span></button>
                </div>
            </ContentContainer>
        </div>
    )
}
import { LogoArea } from "./logo_area";
import { MenuArea } from "./menu_area";
import "./Header.scss";
import { FixedContainer } from "../../pages/page_components";
import { useMatch } from "react-router-dom";
import { menuData } from "../menus";

export const useIsLandingPage = () => {

    const lMatch = useMatch(menuData.landing.route);
    const hMatch = useMatch(menuData.home.route);


    const isTransparent = lMatch != null || hMatch != null;
    return isTransparent;
}

export const Header = () => {
    const isTransparent = useIsLandingPage();
    return (
        <header className={`header ${isTransparent ? "": "bg"}`}>
            <FixedContainer>
                <LogoArea />
                <MenuArea />
            </FixedContainer>
        </header>
    )
}

import { menuDetails } from "../../components/menus";
import { CompName, getCompanyName } from "../about";
import { Breathing, CorporateGame, FirstAid, Fitness, HealthFair, Relaxation } from "./component";
import cpr from "../../assets/images/cpr.jpg";
import yoga from "../../assets/images/yoga.jpg";
import massage from "../../assets/images/massage.jpg";
import corporategame from "../../assets/images/coorporate-game.jpg";
import breathingcls from "../../assets/images/breathing-cls.jpg";
import healthfair from "../../assets/images/healthfair.jpg";

export interface IService {
    title: string;
    summary: string;
    img?: string;
    page?: JSX.Element;
    url?: string;
    image?: string;
    examples?: IService[];
} 


const fitness_examples: IService[] =  [
    {
        title: "Yoga",
        summary: "Immerse yourself in the serenity of our Yoga classes, designed to enhance flexibility, balance, and mindfulness. Led by experienced instructors, our sessions cater to all skill levels, fostering a sense of tranquility and inner strength.",
        url: menuDetails.serviceLink("0/Yoga"), 
        // page: <div>Yoga Details will be here</div>
        page: <span></span>
    },
    {
        title: "Meditation", 
        summary: "Experience the power of stillness with our Meditation classes. Guided by skilled instructors, these sessions provide a sanctuary for relaxation, stress reduction, and mental clarity. Discover the transformative benefits of mindfulness in the midst of your busy life.",
        url: menuDetails.serviceLink("0/Meditation"), 
        // page: <div>Meditation Details will be here</div>
        page: <span></span>
    },
    {
        title: "Zumba", 
        summary: "Get ready to dance, move, and groove with our high-energy Zumba classes! Led by dynamic instructors, these sessions combine Latin and international music with vibrant dance moves, creating a fun and effective cardio workout.",
        url: menuDetails.serviceLink("0/Zumba"), 
        // page: <div>Zumba Details will be here</div>
        page: <span></span>
    },
    {
        title: "Dance", 
        summary: "Unleash your inner dancer with our diverse Dance classes. From contemporary to hip-hop, our experienced instructors offer engaging choreography suitable for all skill levels. Join us for a joyful and expressive dance experience.",
        url: menuDetails.serviceLink("0/Dance"), 
        // page: <div>Dance Details will be here</div>
        page: <span></span>
    },
    {
        title: "Barre", 
        summary: "Sculpt and tone your body with our Barre classes, blending elements of ballet, Pilates, and strength training. Led by certified instructors, these sessions focus on precise movements to target and strengthen specific muscle groups, promoting a lean and sculpted physique.",
        url: menuDetails.serviceLink("0/Barre"), 
        // page: <div>Barre Details will be here</div>
        page: <span></span>
    },
    {
        title: "High-Intensity Interval Training (HIIT)", 
        summary: "Elevate your corporate wellness journey with High-Intensity Interval Training (HIIT), a dynamic fitness approach designed to maximize efficiency and results. HIIT involves short bursts of intense exercise alternated with brief periods of rest, making it a time-efficient and effective workout for busy professionals. Our corporate HIIT classes are crafted to boost cardiovascular health, increase endurance, and enhance overall fitness levels. Tailored to accommodate varying fitness levels, this high-energy workout promotes teamwork, mental resilience, and stress reduction—all essential components for a thriving corporate environment. Engage your team in invigorating HIIT sessions, fostering a culture of health, vitality, and productivity within your workplace.",
        url: menuDetails.serviceLink("0/High-Intensity Interval Training (HIIT)"), 
        // page: <div>High-Intensity Interval Training (HIIT) Details will be here</div>
        page: <span></span>
    }
];

export const ListOfServices: IService[] = [
    {
        title: 'Holistic Fitness Classes', 
        // summary: 'Energize your team with our fitness classes, ranging from invigorating workouts to soothing yoga sessions',
        summary: "Step into a realm of corporate wellness where the joy of movement and holistic well-being is delivered directly to your workplace! Our hosted fitness classes for corporate settings present a diverse range of experiences, spanning the tranquility of Yoga and Meditation to the exhilaration of Zumba, Dance, and the precision of strength-building Barre workouts. Explore a world of fitness crafted to align with your corporate preferences, seamlessly integrated into the comfort of your chosen workplace location. Elevate your team's well-being with our tailored fitness experiences designed for corporate environments.",
        examples: fitness_examples,
        page: <Fitness />,
        image: yoga
    },
    // {title: 'Corporate Wellness Events', summary: 'Equip your team with life-saving skills through our comprehensive first aid and CPR training programs, ensuring a safe and prepared workplace', examples: []},
    {
        title: 'First Aid & CPR Training', 
        summary: `Welcome to our comprehensive First Aid and CPR classes, where we empower individuals to become confident and capable first responders in times of need. At ${getCompanyName()}, we understand the significance of being prepared to handle emergencies, and our expertly crafted courses and certified trainers endorsed by the American Red Cross are designed to provide the knowledge and skills necessary for effective response.`,
        page: <FirstAid />,
        image: cpr
    },
    {
        title: 'Health Fair Hosting', 
        summary: `Elevate your corporate well-being with our Health Fair Hosting services, redefining the landscape of employee health. Our approach is both comprehensive and engaging, aiming to create a dynamic and informative experience tailored to your organization. At ${getCompanyName()}, we are dedicated to fostering a culture of well-being within workplaces. Our Health Fair Hosting services surpass the conventional, offering immersive and educational events that prioritize the health and wellness of your employees. Discover the unique elements that set our Health Fairs apart, as we transform the way corporate well-being is perceived and experienced.`,
        page: <HealthFair />,
        image: healthfair
    },
    {
        title: 'Onsite Relaxation Services', 
        summary: `Indulge in relaxation, heightened productivity, and boosted motivation brought directly to your corporate environment through our Onsite Massage services. Meticulously designed to deliver the therapeutic benefits of both table and chair massages to your chosen location, our team of skilled and licensed massage therapists turns any space into a serene oasis. At ${getCompanyName()}, we are dedicated to seamlessly integrating self-care into your corporate lifestyle. Our Onsite Massage services provide the opulence and healing touch of professional massage therapy right to your doorstep, whether it's your office or event venue. Immerse yourself in the convenience and tranquility of our customized Onsite Massage experiences as we bring relaxation to you.`,
        page: <Relaxation />,
        image: massage
    },
    {
        title: 'Corporate Game Day & Employees Retreat', 
        summary: `We redefine corporate experiences with our innovative offerings in corporate games and employees retreats. Our tailored programs are designed to enhance team dynamics, foster collaboration, and promote a positive work culture. Whether it's a dynamic corporate game day to ignite team spirit or a rejuvenating retreat to inspire creativity and camaraderie, we provide engaging experiences that go beyond the ordinary. 
        At ${getCompanyName()}, we understand the importance of cultivating a positive and motivated workforce. Explore our corporate game and retreat offerings to elevate your team's spirit, creativity, and overall well-being. Transform your corporate environment with memorable experiences that leave a lasting impact on your team.`, 
        page: <CorporateGame />,
        image: corporategame
    },
    {
        title: 'Mindful Breathing Sessions', 
        summary: "Discover the transformative benefits of mindful breathing through our meticulously crafted Breathing Classes, tailored to nurture the well-being of your corporate team. Whether your employees are beginners seeking relaxation or seasoned practitioners, our diverse range of classes caters to all levels of experience. Immerse yourself in a journey of conscious breathing, exploring a spectrum of techniques that promote enhanced well-being, stress reduction, and heightened mental clarity. Whether conducted onsite or virtually, our classes are designed to meet the unique needs of employees working from various locations, ensuring a perfect fit for your team's well-being.",
        page: <Breathing />,
        image: breathingcls
    }
];


// import './NumberInput.scss'

import { useState } from "react";
import { IInput } from "../../../FormHelpers";

interface Props extends IInput { }
export const NumberInput: React.FC<Props> = ({id, name, init, onChange}) => {
    const [value, setValue] = useState(init || '');
    
    const handleChange = (e: any) => {
        const x = e.target.value;
        const v = x.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
        setValue(v);
        onChange({name: name, value: v.number()}, () => setValue(''));
    }

    
    
    return <input id={id} value={value} name={name} onChange={handleChange} />
}
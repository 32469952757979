import { Footer } from "../../components"
interface Props {
    children: any;
    [x: string]: any;
}
export const WithFooter: React.FC<Props> = ({children, ...props}) => {
    return (
        <div {...props}>
            {children}
            <Footer />
        </div>
    )
}
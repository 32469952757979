import styles from "./container.module.scss";

export const FixedContainer: React.FC<any> = ({children}) => {
    return (
        <div className={styles.ContainerPadding} style={{maxWidth: '1200px', margin: "0 auto"}}>
            {children}
        </div>
    )
}


export const ContentContainer: React.FC<any> = ({children, ...props}) => {
    const baseStyle = {maxWidth: '1200px', margin: "2rem auto"};
    const cStyles = !!props.style ? {...baseStyle, ...props.style} : baseStyle;
    return (
        <div className={`${styles.ContainerPadding} content-container`} style={cStyles} >
            {children}
        </div>
    )
}
import { NavLink, To } from "react-router-dom";
import "./AwLink.scss";


interface Props {
    to: To;
    label: string | any;
    clicked?: () => void;// React.MouseEventHandler<any>;
    className?: string;
}

export const AwLink: React.FC<Props> = ({to, label, clicked, className = ""}) => {
    return (
        <NavLink 
            className={`aw-link ${className}`} 
            to={to} 
            onClick={clicked ? (e: any) =>  {
                e.preventDefault();
                clicked();
            } : () => null }>{label}</NavLink>
    )
}
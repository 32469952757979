
import { Content, ContentData, InviteAction } from "../shared";
import "./HealthFair.scss";


const content: ContentData[] = [
    {title: 'Customized Event Planning:', summary: "Tailor your health fair to meet the specific needs and interests of your organization. From thematic setups to wellness focuses, we work closely with you to create a bespoke event."},
    {title: 'Diverse Wellness Offerings:', summary: "Our health fairs encompass a wide array of wellness components, including physical health screenings, mental health resources, nutritional guidance, fitness activities, and more. This diversity ensures a holistic approach to well-being."},
    {title: 'Expert Vendor Collaborations:', summary: "Collaborate with reputable health and wellness vendors who bring their expertise to your event. From local health professionals to fitness instructors and nutritionists, our vendor network ensures high-quality services."},
    {title: 'Interactive Workshops and Demos:', summary: "Engage your employees with interactive workshops and live demonstrations on various health topics. From stress management techniques to fitness routines and healthy cooking classes, our events are both informative and participatory."},
    {title: 'Wellness Challenges and Incentives:', summary: "Encourage participation with wellness challenges and incentives that motivate employees to prioritize their health. Competitions, reward programs, and recognition ceremonies add an element of excitement to the fair."},
    {title: 'Custom Branded Materials:', summary: "Elevate your corporate branding with custom-designed materials for the event. From banners to pamphlets, create a cohesive and branded atmosphere that reflects your company's commitment to employee well-being."},
    {title: '', summary: ""},
    {title: '', summary: ""}
]

const whyUs: ContentData[] = [
    {title: 'Comprehensive Well-being:', summary: "Our health fairs cover a spectrum of well-being components, ensuring a comprehensive approach to employee health."},
    {title: 'Engagement and Participation:', summary: "Foster employee engagement through interactive activities and incentives that make well-being a collective and enjoyable journey."},
    {title: 'Tailored to Your Company Culture:', summary: "We understand that every organization is unique. Our customizable approach ensures that the health fair aligns seamlessly with your company culture and values."}
]

export const HealthFair = () => {
    return (
        <div className="health-fair">
            <Content title="Key Features" data={content} />
            {/* <Content title="Why Us" data={whyUs} /> */}


            <InviteAction />
        </div>
    )
}
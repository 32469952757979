import React, { useRef, useState } from 'react';

import './Upload.scss';
import { IInputValue } from '../../../FormHelpers';

interface Props {
    onUploadComplete: () => void;
    isProfile: boolean;
}

// export const Upload: React.FC<Props> = ({onUploadComplete, isProfile}) => {
//     const ref = useRef<any>();
//     const [photo, setPhoto] = useState(null);
//     // const [loading, setLoading] = useState(false);
//     // const [photoURL, setPhotoURL] = useState(avatar);
//     const {user, saveAndUpdateUserImageAsync} = useAuth();
//     const { loading, handleLoading } = useAppSettings()


//     const handler = (e: any) => {
//         const file = e.target.files[0];
//         if(file) {
//             setPhoto(file);
//         }
//     }
//     const handleBtnClicked = async () => {
//         if(isProfile && saveAndUpdateUserImageAsync) {
//             const prevImage = user.profileImageUrl?.split(`${user.uid}%2F`)[1].split('?')[0];
//             let imgPath;
//             if(prevImage) {
//                 imgPath = `${fbStoragePaths.profiles}/${user.uid}/${prevImage}`;
//             }
//             const res = await saveAndUpdateUserImageAsync(photo, handleLoading, imgPath);
//             if(res.success) {
//                 setPhoto(null);
//                 ref.current.value = null;
//                 onUploadComplete();
//             }
//         }
//     }

//  return (
//     <div className='upload'>
//         <input type="file" onChange={handler} disabled={loading} ref={ref} />
//         {/* {photo &&  <div className='temp-thumbnail'>
//             <img src={photo} />
//         </div>} */}
//         {/* {loading ? <div style={{marginTop: '10px'}}>Saving image...</div> : <Pbutton loading={!photo || loading} clicked={handleBtnClicked} label={'Upload'}/>} */}
//         {/* <Pbutton loading={!photo || loading} clicked={handleBtnClicked} label={'Upload'}/> */}
//     </div>
//  );
// }

interface IUploadInput {
    name: string;
    onSelect: (v: IInputValue, clear: () => void) => void;
    loading?: boolean;
    init?: any;
}
export const UploadInput: React.FC<IUploadInput> = ({name='notneeded', onSelect, loading, init}) => {
    const ref = useRef<any>();
    const [photo, setPhoto] = useState(init || null);

    const handler = (e: any) => {
        const file = e.target.files[0];
        if(file) {
            onSelect({name, value: file}, () => setPhoto(null));
            setPhoto(file);
        }
    }

    return (
        <input type="file" onChange={handler} disabled={loading} ref={ref} />
    )
}
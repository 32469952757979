import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, Routes } from "react-router-dom";
import { About, Contact, Faq, Home, Landing, NotFound, OurServices } from "../../pages";
import { HelpLayout, RootLayout } from "../layouts";
import { menuData, menuDetails } from "../menus";
import { ServiceDetails } from "../../pages/ourservices/component/ServiceDetails";

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path="/"  element={<RootLayout/>} >
            <Route index element={<Landing />} />
            <Route path={menuData.home.route} element={<Landing />} />
            {/* <Route path={menuData.programs.route} element={<Programs />} />
            <Route path={menuData.resources.route} element={<Resources />} /> */}
            <Route path={menuData.ourservices.route} element={<OurServices />} />
            <Route path={menuDetails.serviceDetail.route} element={<ServiceDetails />} />
            <Route path={menuDetails.serviceDetailExample.route} element={<ServiceDetails />} />
            {/* <Route path={menuData.home.route} element={<Home />} /> */}
            <Route path={menuData.about.route} element={<About />} />
            <Route path={menuData.contact.route} element={<Contact />} />
            {/* <Route path={menuData.help.route} element={<HelpLayout />}>
                <Route index element={<Faq />} />
                <Route path={menuData.faq.route} element={<Faq />} />
                <Route path={menuData.contact.route} element={<Contact /> } />
            </Route> */}
            <Route path="*" element={<NotFound />} />
        </Route>
    )
)



export const AppRouter = () => <RouterProvider router={router}/>


// export const AppRouter = () => {
//     return (
//         <Routes>
//             <Route index element={<Landing />} />
//             <Route path={menuData.home.route} element={<Home />} />
//             <Route path={menuData.about.route} element={<About />} />
//         </Routes>
//     )
// }
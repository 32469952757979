import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
// import bg from "../../../assets/images/landing_page.jpg";
import mainBg from "../../../assets/images/main-bg.jpg";
import bg from "../../../assets/images/wk-lf-balance.jpg";
import bg2 from "../../../assets/images/team-pic.jpg";
// import bg2 from "../../../assets/images/landing_page_2.jpg";
import abt from "../../../assets/images/lorcan_about_page.jpg";
import pmg from "../../../assets/images/lorcan_programs_page.jpg";
import { menuData } from "../../../components/menus";
import "./Banner.scss";
import { AwCarousel, ISlide } from "../../../components";
import { AwPicScrollAnimation } from "../../../components/carousel/AwPicScrollAnimation";
import { useSmartScroll } from "../../../components/custom_hooks";
import { debounceFxn } from "../../../utils";
const createSpanWord = (index: number, text: string, parentCls: string) => {
  const span = document.createElement('span');
  span.innerHTML = `${text} `;
  span.classList.add(`${parentCls}_word`);
  // span.style.transitionDelay = `${index * 40}ms`;
  span.style.setProperty('--delay', `${index * 200}ms`);
  return span;
}

const addCreatedSpanWord = (parentEle: any, index: number, text: string) => {
  return parentEle.appendChild(createSpanWord(index, text, parentEle.className));
}

const addContent = (parentEle: any, text: string) => text.split(" ").map((text, index) => addCreatedSpanWord(parentEle, index, text));

function animateFxn(ele: any) {
  const title = "Healthy Today, Thriving Tomorrow.";
  
  const hTitle = ele.querySelector('.hero-title');
  // const hSubTitle = ele.querySelector('.hero-subtitle');
  const titleTextC = hTitle.textContent;
  const titleHtmlTxt = hTitle.innerHTML;
  if(!titleTextC || !titleHtmlTxt) {
    addContent(hTitle, title);
  }

}
export const Banner = () => {
    const bannerRef = useRef<any>();
    

    useEffect(() => {
      const ele = bannerRef.current;
      if(ele) {
        animateFxn(ele);
      }
      return () => {}
    }, [])

    // const debounce = debounceFxn(animateFxn, 300);

    // useEffect(() => {
    //   const ele = bannerRef.current;
    //   if(ele) {
    //     debounce(ele);
    //   }
    //   return () => {}
    // }, [])


    const parallaxFn = () => {
      const banner: HTMLElement = bannerRef.current;
      let slideContainer: any;
      let adjustedScale = 1;
          // const header = banner.querySelector('header') as HTMLElement;
          // const overLay = banner.querySelector('#b-overlay') as HTMLElement;
          // const img = banner.querySelector('img') as HTMLElement;
          
          if(banner) {
            // parallaxEffect(banner);
          const rect = banner.getBoundingClientRect();

          const offset = (rect.top * -1);
          const bannerHeight = rect.height;

          const slides = banner.querySelectorAll(".landing-banner-slides");
          // slides.forEach(slide => (slide as any).style.backgroundPositionY = `${offset * 0.7}px`);
          slides.forEach(s => {
            let slide = (s as any);
            let adjustedOffset = offset * 0.1;
            slide.style.backgroundPositionY = `${adjustedOffset}px`;

            // 3D
            const scrollY = window.scrollY;
            let rotateX = (scrollY / bannerHeight) * 60;
            
            // if(!slideContainer) {
              slideContainer = slide.parentElement;
              slideContainer.style.perspective = '1500px';
            // } else {
              const containerHeight = slideContainer.offsetHeight;

              
              // slideContainer.style.perspective = '1500px';
              let adjustedXRotation = adjustedOffset * 2;
              if(adjustedXRotation > 36) {
                adjustedXRotation = 36;
              }
              if(adjustedXRotation < 0) {
                adjustedXRotation = 0;
              }
              // console.log(adjustedXRotation);

              // slide.style.transform = `rotateX(${adjustedXRotation}deg) scaleX(${adjustedScale * 1.05})`;
              slide.style.transform = `rotateX(${rotateX}deg) scaleX(${adjustedScale * 1.05})`;
              slideContainer.style.top = `${adjustedXRotation}px`;
  
            // }
            

            
          });
          }
    }
    useSmartScroll(bannerRef, parallaxFn);

    const slides: ISlide[] = [
      
      {
        imageUrl: bg,
        caption: 'Slide 0',
      },
      // {
      //   imageUrl: abt,
      //   caption: 'Slide 2',
      // },
    
      // {
      //   imageUrl: pmg,
      //   caption: 'Slide 3',
      // },
      {
        imageUrl: bg2,
        caption: 'Slide 1',
      },
      {
        imageUrl: mainBg,
        caption: 'Slide 2',
      },
      
    ];

    return (
       <>
        <div className="banner" ref={bannerRef}>
            {/* <img src={bg} alt="" /> */}
            <AwCarousel pgName="landing-banner-slides" slides={slides} />
            {/* <div className="content">
                <h1>Holistic </h1>
                <h1 className="h1-main gradient-text">Employee Health and Wellness</h1>
                <h1> Programs</h1>
                    <ProgramsLooper />
                <NavLink className="nav-btn" to={menuData.programs.route}>Read More</NavLink>
            </div> */}
            <div className="content">
              {/* <h1>HEALTHY TODAY, THRIVING TOMORROW. WE PRIDE
  OURSELVES IN IMPROVING EMPLOYEES WELLBEING.</h1> */}
              <h1 className="hero-title"></h1>
              <h2 className="hero-subtitle"> We Pride Ourselves In Improving Employees Wellbeing.</h2>
            </div>
        </div>
       {/* <AwPicScrollAnimation  slides={slides} /> */}
       </>
    );

}


const ProgramsLooper = () => {
    const [textIndex, setTextIndex] = useState(0);
  const texts = [
    'Biometric Body Analyzer', 
    'Yoga/Meditation/Massage', 
    'Chefs/Nutritionist/Dietitian', 
    'Kitchen Design layout/gym/renovate office or company', 
    'Health coaches / Personal trainers', 
    'Speakers/ Team building activities',
    'Health fair',
    "Speakers/ Team building activities",
    "Employees gift"
];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setTextIndex((textIndex + 1) % texts.length);
    }, 5000);

    return () => clearInterval(intervalId);
  }, [textIndex, texts.length]);

  return (
    <div style={{margin: '20px 0'}}>
      <h3>{texts[textIndex]}</h3>
    </div>
  );
}
import { forwardRef, useEffect, useState } from 'react';
import ReactDOM  from 'react-dom';
import { useNavigate } from 'react-router-dom';
import { Arrow, Close } from '../../icons';
import { IMenu, menuData, menus } from '../../menus';
import { useModalFunctions } from '../../modals/useModalFunctions';
// import { useAuth } from '../../../context';
// import { IMenu, menuList, urls } from '../menu-list';


interface IMenuSetting {
    menus: IMenu[]; 
    show: Boolean;
}

export const MobileMenuModal = forwardRef<any, any>((_, ref) => {
    const {display, close } = useModalFunctions({ref, settings: {}});

    const navigate = useNavigate();
    // const {user, logoutAsync} = useAuth();

    const [mainMenus, setMainMenu] = useState<IMenu[]>(menus);

    const [dropdown, setDrop] = useState<IMenuSetting>({menus: [], show: false});

    const setDropdownMenus = (menus: IMenu[]) => {
        setDrop(state => ({...state, menus: menus, show: true}));
    }
    const showHideDropdownMenu = () => {
        setDrop(state => ({...state, show: !state.show}));
    }
    const resetMenus = () => {
        setDrop(state => ({...state, show: false}));
    }

    const handleClose = () => {
        resetMenus();
        close();
    }
    
    const handleNavigate = (url: string) => {
        navigate(url);
        handleClose();
    }

    const handleLogout = async () => {
        // if(logoutAsync) await logoutAsync();
        handleClose();
        navigate(menuData.home.route);
    }

    useEffect(() => {
        // if(user.isAuthenticated) {
        //     const filterMenu = menuList.filter(mm => (mm.url as any).lowerCase() != urls.signin);
        //     setMainMenu(filterMenu);
        //     if(!user.isAdmin) {
        //         // if its not admin
        //         setMainMenu(menuList.filter(mm => !(['admin', 'signin', 'home'].includes((mm.name as any).normalize())) ) )
        //     }
        // } else {
            // setMainMenu(menus.filter(mm => ([menuData.home.route, menuData.programs.route, menuData.about.route, menuData.contact.route].includes(mm.route))))
        // }
    },[])
// },[user])
    

    if(!display) return null;

   
    return ReactDOM.createPortal(
        <div className="mobile-menu-wrapper show">
        <div className="backdrop" onClick={handleClose}></div>
        <div className="content">
        <div className="header">
            <div className="left-btns">
            {dropdown.show && (
                <Arrow dir="left" clicked={() => showHideDropdownMenu()}>
                    <div>Back</div>
                </Arrow>
            )}
            </div>
            <Close clicked={handleClose}/>
        </div>
        <div className="menus" style={{overflowY: 'scroll'}}>
            {!dropdown.show && <MenuList 
                                    menus={mainMenus} 
                                    setDropdown={setDropdownMenus} 
                                    navigate={handleNavigate}/>}
            {dropdown.show && <MenuList 
                                    menus={dropdown.menus} 
                                    setDropdown={setDropdownMenus}
                                    navigate={handleNavigate}/>}

            
        </div>
        
        </div>
    </div>,
    (document.getElementById('mobile-menu-root') as HTMLDivElement)
   )
})



const MenuList: React.FC<{menus: IMenu[]; navigate: (v:string) => void; setDropdown: (menus: IMenu[]) => void; }> = ({menus, navigate, setDropdown}) => {
    return (<>
        {menus.map((menu, i) => {
            return (
                <MenuItem menu={menu} key={i} navigate={navigate}  setDropdown={setDropdown} />
            )
        })}
    </>)
}

const MenuItem: React.FC<{menu: IMenu; navigate: (v:string) => void; setDropdown: (menus: IMenu[]) => void; }> = ({menu, navigate, setDropdown}) => {
    // const {rootUrl} = useAuth();
    const onlyDropdown = menu.children && !menu.route;

    return (
        <div className="menu">
            {onlyDropdown ? (
                <div className="mobile-menu-link" onClick={() => setDropdown(menu.children as IMenu[])}>
                    <span>{menu.name} </span>
                    <span><Arrow dir="right" /></span>
                </div>
            ) : (
                <div>
                    {/* {!!menu.url ? <span className="mobile-menu-link" onClick={() => navigate(menu.name.normalize() === 'dashboard' ? rootUrl : menu.url)}>{menu.name} </span> : <span>{menu.name} </span> } */}
                    {!!menu.route ? <span className="mobile-menu-link" onClick={() => navigate(menu.route)}>{menu.name} </span> : <span>{menu.name} </span> }
                    {menu.children && <span onClick={() => setDropdown(menu.children as IMenu[])}><Arrow dir="right" /></span>}
                </div>
            )}
        </div>
    )
}


import { AwLink } from "../../../../../components"
import { menuData } from "../../../../../components/menus";

import "./InviteAction.scss";

export const InviteAction = () => {
    return (
        <div className="invite-action-container">
            <p>
                Ready to boost employee experience and organizational outcomes? <AwLink label="Contact us" to={menuData.contact.route}/>.
            </p>
        </div>
    )
}
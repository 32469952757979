import { PatternBackground } from "../../components/helpers"
import { ContentContainer } from "../page_components";
import bg from "../../assets/images/lorcan_about_page.jpg";
// import team from "../../assets/images/team.png";
import team from "../../assets/images/lorcan-team.jpg";
import founder from "../../assets/images/linda.png";
import { Section } from "./components";

import "./About.scss";
import React from "react";


export const About = () => {
    return (
        <div className="about">
            {/* <ImageBackground bgImg={bg}>
                <h1>
                    Lorcan
                </h1>
            </ImageBackground> */}
            <PatternBackground>
                <ContentContainer>
                    <h1 style={{letterSpacing: '0.1rem'}}>About Us</h1>
                </ContentContainer>
            </PatternBackground>
            <ContentContainer>
                <article>
                {/* <Section title="Our Company" img={bg}>
                    <div className="info-area">
                        <p>Welcome to <CompName />, where corporate well-being takes center stage! We are a dynamic team of wellness experts committed to transforming workplaces into hubs of happiness, health, and productivity. </p>
                        <p><span className="mi">Our Mission: </span> At <CompName />, our mission is clear: to foster a culture of well-being within corporate environments. We believe that when employees are happy and healthy, they not only thrive individually but contribute to a thriving, successful company.</p>
                    
                        <p><span className="mi">Our Vision: </span> To be a relentless force of change for employees and help them embrace a healthy lifestyle.</p>
                   
                    </div>
                </Section> */}
                <section style={{lineHeight: '2'}}> 
                <div className="info-area">
                        <p>Welcome to <CompName />, where corporate well-being takes center stage! We are a dynamic team of wellness experts committed to transforming workplaces into hubs of happiness, health, and productivity. </p>
                        <p><span className="mi">Our Mission: </span> At <CompName />, our mission is clear: to foster a culture of well-being within corporate environments. We believe that when employees are happy and healthy, they not only thrive individually but contribute to a thriving, successful company.</p>
                    
                        <p><span className="mi">Our Vision: </span> To be a relentless force of change for employees and help them embrace a healthy lifestyle.</p>
                   
                    </div>
                </section>

                {/* <Section title="Our Team" img={team} className="right"> */}
                <Section title="Our Team" img={team}>
                    <div className="info-area ">
                        <p>
                        <CompName /> is a team of wellness experts, each with a wealth of experience in delivering effective solutions across diverse fields. With a shared passion for well-being, we bring a holistic approach to corporate wellness that goes beyond the ordinary. 
                        </p>
                    </div>
                </Section>

                {/* <Section title="" img={founder}
                    imgDetail={(<div className="img-detail">
                        <p>Linda Ngalle</p>
                        <h2>Founder</h2>
                    </div>)}
                >
                    <div className="info-area">
                        
                        <p>My passion for corporate wellness is deeply rooted in personal experiences, recognizing the challenges that individuals, particularly immigrants, face in maintaining a balanced and healthy lifestyle amidst the demands of a corporate setting. With <CompName name="LORCAN Corporate Worksite Solutions"/>, my aim is to bridge this gap by offering comprehensive wellness solutions that resonate with diverse backgrounds and aspirations.</p>
                        
                        
                    </div>
                </Section> */}
                </article>
            </ContentContainer>
        </div>
    )
}


interface CompProp {
    name?: string;
}
export const CompName: React.FC<CompProp> = ({name = "LORCAN Wellness"}) => {
    return (
        <strong className="comp-name">{name}</strong>
    )
}
export function getCompanyName(name = "LORCAN Wellness") {
    return `<strong class="comp-name"> ${name}</strong>`
}


import "./Section.scss";


interface Props {
    title?: string;
    className?: string;
    img?: string;
    imgDetail?: JSX.Element;
    children: any;
}

export const Section: React.FC<Props> = ({title,  children, className="", img, imgDetail = (<></>)}) => {
    return (
        <section className={`sec-container ${className}`}>
            {title && <h1 className="gradient-text">{title}</h1>}
            <div className="content">
                <div className="image">
                    <div>
                    <img src={img} alt="" />
                    {imgDetail}
                    </div>
                </div>
                {children}
            </div>
        </section>
    )
}
import { AwLink } from "../../../../components";
import { menuDetails } from "../../../../components/menus";
import { Content, ContentData, InviteAction } from "../shared";
import "./Breathing.scss";


const content: ContentData[] = [
    {title: '<span>Mindful Breathing Sessions:</span>', summary: "Benefits: Promotes focus, reduces stress, and cultivates a calm and centered mindset."},
    {title: '', summary: "Ideal for: Employees looking to enhance mindfulness and manage workplace stress."},
    {title: '<span>Stress Relief Breathwork Workshops:</span>', summary: "Benefits: Alleviates stress, enhances relaxation, and provides practical techniques for coping with workplace pressures."},
    {title: '', summary: "Ideal for: Teams dealing with high-stress situations or deadlines."},
    {title: '<span>Energizing Breath Practices:</span>', summary: "Benefits: Boosts energy levels, increases mental alertness, and revitalizes employees during the workday."},
    {title: '', summary: "Ideal for: Afternoon sessions to combat post-lunch fatigue and enhance focus."},
    {title: '<span>Breath and Movement Fusion Classes:</span>', summary: "Benefits: Integrates breathwork with gentle movement to enhance flexibility, release tension, and promote mind-body connection."},
    {title: '', summary: "Ideal for: Teams seeking a blend of physical activity and stress reduction."},
    {title: '<span>Quick Stress Buster Sessions:</span>', summary: "Benefits: Provides short, effective breathing exercises that can be done at the desk to quickly alleviate stress."},
    {title: '', summary: "Ideal for: Busy employees who need quick and accessible stress relief."},
    {title: '<span>Mindful Breathing for Improved Concentration:</span>', summary: "Benefits: Focuses on concentration-enhancing techniques to improve attention and productivity."},
    {title: '', summary: "Ideal for: Teams involved in tasks requiring sustained focus."},
    {title: '<span>Breath Awareness and Relaxation Workshops:</span>', summary: "Benefits: Teaches employees how to use breath awareness for relaxation, reducing anxiety, and improving overall well-being."},
    {title: '', summary: "Ideal for: General wellness initiatives and stress management programs."},
    {title: '<span>Corporate Wellness Retreats with Holotropic Breathwork:</span>', summary: "Benefits: Offers a more in-depth and transformative experience for team bonding and stress relief."},
    {title: '', summary: "Ideal for: Periodic wellness retreats or team-building events."},
    {
        title: '<span>Breathing to the beat</span>', summary: "Embark on a journey where well-being converges with the pulse of life in our distinctive corporate offering. Uplift your team's health with this groundbreaking method for stress relief and mindfulness. Our philosophy revolves around aligning well-being with life's rhythmic flow. Enter the realm of 'Breathing to the Beat,' an innovative corporate wellness initiative seamlessly blending the potency of breathwork with the vibrant cadence of music. Crafted to immerse participants in an enriching experience, this program is tailored to instill equilibrium, concentration, and vibrancy within your workplace."
        , additionalSummary: <><br/> <span>Read More...</span></>,// (<AwLink label="Read More..." to={menuDetails.serviceLink("BreathingToTheBeat")} />)
},
    // {title: '', summary: ""},
    // {title: '', summary: ""}
]



export const Breathing = () => {

    return (
        <div className="breathing">
            <Content title="Key Features"  data={content}/>
            
            <InviteAction />
        </div>

    )
}
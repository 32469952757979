import "./MenuIcon.scss";


export const MenuIcon = () => {
    return (
        <div className="menu-icon">
            <div></div>
            <div></div>
            <div></div>
        </div>
    )
}
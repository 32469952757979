import { Content, ContentData } from "../../../ourservices/component/shared"
import { ContentContainer } from "../../../page_components"

import "./WhyLorcan.scss";

const reasons: ContentData[] = [
    // {title: "", summaryTitle: "Proven Success: ", summary: "Our Corporate Game Day or retreat experiences have a track record of success, with many satisfied clients praising the positive impact on teamwork and employee engagement"},
    // {title: "", summaryTitle: "Customization: ", summary: `We believe in tailoring our offerings to meet your specific needs. Our team works closely with yours to create an experience that aligns with your corporate culture and goals.`},
    // {title: "", summaryTitle: "Unforgettable Memories: ", summary: "Beyond the team-building benefits, our Corporate Game Day is designed to create lasting memories that contribute to a positive and vibrant workplace culture."},
    {title: "", summaryTitle: "Employee-Centric Well-Being: ", summary: "We tailor our wellness programs to prioritize the physical and mental health of your employees, creating a workplace that cares for its greatest asset."},
    {title: "", summaryTitle: "Thriving Together: ", summary: "Our philosophy extends beyond individual well-being. We envision a workplace where the collective wellness of your team propels the entire company to new heights of success."},
    {title: "", summaryTitle: "Inclusive Approach: ", summary: "Recognizing that well-being is diverse, we offer a range of programs from mindfulness sessions and fitness classes to team-building corporate game days."},
    {title: "", summaryTitle: "Long-Term Success: ", summary: "We understand that a healthy and happy workforce is the bedrock of a successful company. Invest in your team's well-being for sustained growth and prosperity."},
    {title: "", summaryTitle: "", summary: ""},
]

export const WhyLorcan = () => {
    return (
        <div className="why-lorcan">
            <ContentContainer>
                <h1>Why Choose Us</h1>
                <Content title="" data={reasons} />
            </ContentContainer>
        </div>
    )
}
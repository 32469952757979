import "./Content.scss";


export interface IContent {
    title: string;
    data: ContentData[];
}

export const Content: React.FC<IContent> = ({title, data}) => {
    return (
        <div className="d-content">
            <h2>{title}</h2>
            {data.map((item, idx) => {
                return <ContentItem key={idx} data={item} />
            })}
        </div>
    )
}


export interface ContentData {
    title: string;
    summary: string;
    summaryTitle?: string;
    additionalSummary?: any;
}
const ContentItem: React.FC<{data: ContentData}> = ({data}) => {
    return (
        <div className="item">
            {/* <h3 className="gradient-text">{data.title}</h3> */}
            <h3 className="gradient-text" dangerouslySetInnerHTML={{__html: data.title }} />
            <ul className="how-it-works-list-wrapper">
                <li>
                    <p>
                        <strong>{data.summaryTitle}</strong>
                        {/* {data.summary} */}
                        <span dangerouslySetInnerHTML={{__html: data.summary }}/>
                        {!!data.additionalSummary && data.additionalSummary}
                    </p>
                </li>
            </ul>
        </div>
    )
}
import { useAwNavigate } from "../../../../components/custom_hooks";
import { menuData } from "../../../../components/menus";
import { CompName } from "../../../about"
import { ContentContainer } from "../../../page_components"

import "./WhyLorcanSummary.scss";

export const WhyLorcanSummary = () => {
    const {navigate} = useAwNavigate();
    return (
        <ContentContainer>
            <div className="demo-content">
                <div>
                    {/* <p>At , we believe in addressing all aspects of well-being—physical, mental, and emotional. Our programs are customizable to meet the unique needs and goals of each company.</p> */}
                <p>
                At <CompName name="LORCAN Corporate Wellness Solution" />, we are dedicated to redefining the workplace experience by prioritizing the well-being of your greatest asset – your employees. We understand that a happy and healthy workforce is not only more productive but contributes significantly to the success and prosperity of your company.
                </p>
                </div>
                {/* <p>Benefit from the expertise of our seasoned wellness professionals dedicated to your team's success. </p> */}

                {/* <button className="accent" onClick={() => navigate(menuData.contact.route)}>
                    <span>Request Demo</span>
                </button> */}
            </div>
        </ContentContainer>
    )
}
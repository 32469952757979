import { AwLink } from "../../../../components";
import { menuDetails } from "../../../../components/menus";
import { ListOfServices } from "../../allServices";
import { Content, ContentData, InviteAction } from "../shared";


const content: ContentData[] = [
    {title: "Convenience at Your Doorstep:", summary: "We bring the fitness studio to you! Enjoy our classes in the comfort of your home, office, or any location of your choice."},
    {title: "Expert Instructors:", summary: "Our certified instructors bring a wealth of experience and expertise to each class, ensuring a safe, effective, and enjoyable fitness experience."},
    {title: "Tailored Sessions:", summary: "Whether you're a beginner or an experienced fitness enthusiast, our classes cater to various skill levels and fitness goals. Customize your experience for a workout that suits your needs."},
    {title: "Group or Individual Sessions:", summary: "Choose from group sessions for a lively and social atmosphere or opt for individualized sessions for personalized attention and focus."},
    {title: "Variety of Classes:", summary: "Explore a diverse range of fitness classes, allowing you to mix up your routine and discover new ways to stay active and healthy."},
    // {title: "", summary: ""},
    // {title: "", summary: ""},
    // {title: "", summary: ""},
]
export const Fitness = () => {
    const services = ListOfServices[0].examples!;
    return (
        <div className="fitness">
            <Content title="Key Features" data={content} />


            <h2 className="gradient-text" style={{padding: '3rem 0 1rem'}}>Read more on the following classes</h2>
            
            <ul>
                {
                    services.map((ex, id) => {
                        return (
                            <li key={id}>
                                <AwLink to={ex.url!} label={ex.title} />
                            </li>
                        )
                    })
                }
            </ul>

            <InviteAction />
        </div>
    )
}
import React, { useState } from 'react';
import { IFieldProps } from '../../Field';
import { DateInput, DateTimeInput } from '../../types/date';


import './DateTimeField.scss';
interface Props extends IFieldProps {
    id: string;
    label?: string;
}
export const DateTimeField: React.FC<Props> = ({id, initObj={}, field, label, changeHandler}) => {
    const [value, setValue] = useState(initObj[field.name]);
    const handleChange = (x: any) => {
        changeHandler(x, () => setValue(new Date()));
    }
 return (
    <div className="field date-field">
        <label htmlFor={id}>{label || field.label || field.name} {field.required && <small style={{color: 'red'}}>*</small>}</label>
        <DateTimeInput init={value} id={id} name={field.name} onChange={handleChange} />
    </div>
 );
}

export const DateField: React.FC<Props> = ({id, initObj={}, field, label, changeHandler}) => {
    const [value, setValue] = useState(initObj[field.name]);
    const handleChange = (x: any) => {
        changeHandler(x, () => setValue(new Date()));
    }
    return (
       <div className="field date-field">
           <label htmlFor={id}>{label || field.label || field.name} {field.required && <small style={{color: 'red'}}>*</small>}</label>
           <DateInput init={value} id={id} name={field.name} onChange={handleChange} />
       </div>
    );
   }
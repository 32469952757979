
import { Content, ContentData } from "../../../ourservices/component/shared"
import { ContentContainer } from "../../../page_components"

// import "./WhyLorcan.scss";

const reasons: ContentData[] = [
    {title: "Consultation: ", summary: "Schedule a consultation with our team to begin your well-being journey with a personalized consultation. Our experts will understand your company's unique needs, employee demographics, and wellness goals to tailor a program that fits seamlessly into your corporate culture."},
    {title: "Customized Program Design: ", summary: "Based on the consultation, we design a customized well-being program for your organization. This may include a mix of breathing classes, corporate game days, fitness classes (Yoga, Meditation, Zumba, Dance, and Barre), First Aid & Recover CPR training, Onsite Massage services, and Health Fairs. We ensure the program aligns with your corporate objectives and resonates with your employees."},
    {title: "Implementation: ", summary: "Our experienced team takes care of all the logistics. From setting up game days to coordinating fitness classes and managing Onsite Massage services, we handle the details to ensure a smooth and enjoyable experience for your employees."},
    {title: "Engagement and Participation: ", summary: "We understand that engagement is key to the success of any wellness program. We actively promote participation through tailored communication strategies, incentives, and a user-friendly platform that keeps employees informed and motivated."},
    {title: "Measure and Adjust: ", summary: "Regular assessments help us gauge the impact of the wellness program. We analyze participation rates, gather feedback, and make necessary adjustments to ensure continuous improvement and maximum well-being benefits for your employees."},
    {title: "Results and Impact: ", summary: "Experience the positive transformation within your workplace. Increased employee satisfaction, improved well-being, enhanced productivity, and a thriving corporate culture – witness the tangible results of investing in your employees' health and happiness."},
    // {title: "", summary: ""},
    // {title: "", summary: ""},
    // {title: "", summary: ""},
    // {title: "", summary: ""},
    // {title: "", summary: ""},
    // {title: "", summaryTitle: "", summary: ""},
]

export const HowItWorks = () => {
    return (
        <div className="how-it-works">
            <ContentContainer>
                <Content title="How It Works" data={reasons} />
            </ContentContainer>
        </div>
    )
}

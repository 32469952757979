import { ImageBackground } from "../../components/helpers"
// import bg from "../../assets/images/only_contact_page.jpeg";
import bg from "../../assets/images/contact-page.jpg";
import { Form, fieldTypes } from "../../components/form";
import { ContentContainer } from "../page_components";
import logoName from '../../assets/logos/hd_tranaparent_logo_name.png';
import contactImg from "../../assets/images/contact.png";
import servicesImg from "../../assets/images/services.png";
// const sgMail = require('@sendgrid/mail');


import { useEffect, useRef, useState } from 'react';
import { IEmailForm, sendEmailAsync, sendFormEmailAsync } from '../../utils';
import { useAlert } from '../../components';
import "./Contact.scss";


const contacts = {
    phone: '(888) 909-9987',
    email: 'infos.lorcan@gmail.com'
}


export const Contact = () => {
    
   
    return (
        <div className="contact">
            <div className="main-content-bg" style={{backgroundImage: `url(${bg})`}}>
                <div className="main-content-bg-overlay"></div>
            </div>
            <ContentContainer style={{ margin: '0 auto', position: 'relative', zIndex: '1'}}>
                <div className="wrapper">
                    <Faq />
                    <ContactForm />
                </div>
            </ContentContainer>
            
            {/* <div className="contact-fw">
                <div className="main-content-bg" style={{backgroundImage: `url(${bg})`}}></div>
            <ContentContainer>
                <div className="contact-wrapper">
                    
            
                </div>
            </ContentContainer>
            </div>

            */}
        </div>
    )
}


const Faq = () => {
    return (
        <div className="faq">
            <div className="top">
                <div className="fak-logo">
                    <h1>How can we help<span className="sm-scn">?</span> <span className="bg-scn">you today?</span></h1>
                    <div className="info-intro-wrapper">
                        <p className="info-intro"><span>Email</span>? <span>Text</span> or <span>Call</span>? We are here for you.</p>
                    </div>
                </div>
                {/* <div><strong>Phone:</strong> {contacts.phone}</div>
                <div><strong>Email:</strong> {contacts.email} </div> */}
            </div>
            <div className="bottom">
                <div className="bottom-info-intro">
                    <h2 className="gradient-text"><span>Email</span>? <span>Text</span> or <span>Call</span>? We are here for you.</h2>
                </div>
                <div className="contact-img-wrapper">
                    <img src={contactImg} alt="contact-info" />
                </div>
                <div className="service-img-wrapper">
                    <img src={servicesImg} alt="services-details" />
                </div>
            </div>
        </div>
    )
}

interface Props {
    callback?: () => void;
}

const ContactForm: React.FC<Props> = ({callback}) => {
    const formContainerRef = useRef<any>();
    const [emailSent, setSent] = useState({sent: false, msg: ''});
    const defaultFields = [
        {name: "name", required: true},
        // {name: "lastName", required: true},
        {name: "email",  type: fieldTypes.email, required: true},
        {name: "mobile", label: "Phone Number", required: true},
        // {name: "title", label: "Job Title", required: true},
        {name: "companyName", required: true},
        // {name: "employees", label: "Number of Employees", required: true},
        {name: "message", required: true, type: fieldTypes.textarea },
    ];
   const [formFields, resetFields] = useState(defaultFields);

    const reasonOptions = [
        {name: "General"},
        {name: "Yoga"},
        {name: "Jogging"},
    ];
    const formName = "Message Us";


    const handleSubmit = async (values: any) => {
        
        if(callback) {
            callback();
        }

        const fv: IEmailForm = {
            user_name: values["name"],
            // user_email: `${values["email"]} (${values['mobile']}) (${values['companyName']})`,
            user_info: `${values["email"]} (${values['mobile']}) (${values['companyName']})`,
            subject: "General",// values["reason"],
            message: values["message"],
        }

        const res: any = await sendEmailAsync(fv);
        if(res.success) {
            setSent({sent: true, msg: 'Message Received! We will get back to you.'});
            resetFields(state => (defaultFields));
            window.scrollTo({
                top: formContainerRef.current.offsetTop,
                behavior: 'smooth',
            });
        } else {
            setSent({sent: false, msg: 'Please try again later!'});
        }
        
    }

    useEffect(() => {
        // function run() {
        //     for (var i = 1; i <= 3; i++) {
        //         setTimeout(function () {console.log(`i = ${i}`)}, i * 1000)
        //     }
        // }
        // run();
    }, [])
    
    return (
        <div className="contact-form" ref={formContainerRef}>
                {/* <h1>Leave Us A Message </h1> */}
                {/* <h1>Ready to take the next step?</h1>
                <p>Tell us a little about yourself and leave us a message.</p> */}
                {/* <h1 className="gradient-text">Email Our Team</h1> */}
                {/* {emailSent.msg && (
                <div className="b4-form" style={{ color: emailSent.sent ? 'green' : 'red',  margin: '15px 0'}}>
                    <div>
                        
                    </div>
                    <div>
                    {emailSent.sent && <span style={{fontSize: "1.1rem", fontWeight: 'bold'}}>&#x2713;</span>} {emailSent.msg}
                    </div>
                </div>
            )} */}
                {/* <Form submitted={emailSent.sent} onSubmit={handleSubmit} formName="Message Us" settings={{canSubmit: false}} fields={[
                    {name: "name", required: true },
                    {name: "email", required: true, type: fieldTypes.email },
                    {
                        name: "reason", 
                        required: true, 
                        type: fieldTypes.dropdown,
                        dropdown: {
                            dKey: "name", 
                            vKey: "name", 
                            options: reasonOptions,
                        }
                    },
                    {name: "message", required: true, type: fieldTypes.textarea },
                ]} /> */}

                <div className="form-wrapper">
                    {emailSent.msg && (
                    <div className="b4-form" style={{ color: emailSent.sent ? 'green' : 'red',  margin: '15px 0'}}>
                        <div>
                            
                        </div>
                        <div>
                        {emailSent.sent && <span style={{fontSize: "1.1rem", fontWeight: 'bold'}}>&#x2713;</span>} {emailSent.msg}
                        </div>
                    </div>
                )}
                <Form 
                    submitted={emailSent.sent} 
                    onSubmit={handleSubmit} 
                    formName="Message Us" 
                    settings={{canSubmit: false}}
                    fields={formFields} 
                />
                </div>
                
            </div>
    )
}
import { useRef } from 'react';
import './MobileMenu.scss';
import { MobileMenuModal } from './MobileMenuModal';
import menuIcon from "../../../assets/icons/menu.png";
import { MenuIcon } from '../../icons';
import { useIsLandingPage } from '../Header';


export const MobileMenu = () => {
    // const isLandingPage = useIsLandingPage();
    const funcRef = useRef();

    const openModal = () => {
        (funcRef.current as any).open();
    }

    return (
        <div>
            <div className="mobile-menu-btn-wrapper">
                {/* <button onClick={openModal} className={`btn-menu ${isLandingPage ? "landing" : ""}`}><p>Menu</p><MenuIcon /></button> */}
                <button onClick={openModal} className={`btn-menu landing`}><p className='btn-title'>Menu</p><MenuIcon /></button>
            </div>
            <MobileMenuModal ref={funcRef} />
        </div>
    )
}

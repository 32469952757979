import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import {App} from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom';
import { AppRouter, Footer, Header } from './components';
import { SearchableInput } from './delete-input/SearchableInput';

export const rootId = "root";

const root = ReactDOM.createRoot(
  document.getElementById(rootId) as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <div>
      <SearchableInput />
      <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. At blanditiis laboriosam praesentium ad possimus quis debitis eaque animi illum saepe, accusantium accusamus quo, id dolor cum. Voluptatem, odio? Et, neque!</p>
    </div> */}
    {/* <h1 style={{display: 'grid', placeItems: 'center', height: '100vh'}}>Under Development</h1> */}
    <AppRouter />
    {/* <BrowserRouter>
      <div style={{display: 'flex', flexDirection: 'column', minHeight: `calc(100vh)`}}>
      <Header />
      <main style={{flexGrow: 1}}>
        <App />
      </main>
      <Footer />
      </div>
    </BrowserRouter> */}
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

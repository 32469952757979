import { Content, ContentData, InviteAction } from "../shared"

const content: ContentData[] = [
    {title: 'Tailored Relaxation:', summary: "Experience personalized relaxation with our skilled massage therapists who tailor each session to your specific needs and preferences. Choose from a variety of massage styles, including Swedish, deep tissue, and chair massage."},
    {title: 'Convenience Where You Are:', summary: "We understand the demands of a busy schedule, which is why our Onsite Massage services are designed to fit seamlessly into your day. No need to commute to a spa; we bring the spa experience to you."},
    {title: 'Corporate Wellness Programs:', summary: "Elevate your workplace wellness with our Corporate Onsite Massage programs. Boost employee morale, reduce stress, and increase productivity by providing rejuvenating massage sessions right in the office."},
    {title: 'Event Relaxation Stations:', summary: "Add a touch of relaxation to your events! Whether it's a conference, trade show, or special celebration, our Onsite Massage services create a unique and memorable experience for your attendees"},
    {title: 'Licensed and Professional Therapists:', summary: "Rest assured, our team consists of licensed and experienced massage therapists who prioritize your well-being. Enjoy the benefits of therapeutic touch delivered by skilled practitioners."},
    {title: 'Flexible Booking Options:', summary: "Choose the duration and type of massage that suits your needs. Our flexible booking options make it easy to incorporate the healing power of massage into your routine."},
    {title: '', summary: ""}
]

export const Relaxation = () => {
    return (
        <div className="relaxation">
            <Content title="Key Features" data={content} />

            <InviteAction />
        </div>
    )
}
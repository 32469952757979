import { NavLink, useMatch } from "react-router-dom";
import { menuData, menus } from "../../menus";

import "./MenuArea.scss";
import { MobileMenu } from "../mobile_menu/MobileMenu";

export const MenuArea = () => {
    const match = useMatch(`${menuData.landing.route}`);
    const isActive = match != null;
    return (
        <>
            <nav className="menu-area">
                {menus.map((menu, idx) => (
                    menu.name === menuData.home.name ? <NavLink key={idx} className={isActive ? "active" : ""} to={menu.route}  >
                    {menu.name}
                    </NavLink> :
                    <NavLink key={idx} to={menu.route}>
                        {menu.name}
                    </NavLink>
                ))}
            </nav>
            <MobileMenu />
        </>
        
    )
}
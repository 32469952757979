import { useAwNavigate } from "../../components/custom_hooks";
import { menuData } from "../../components/menus";
import { ContentContainer } from "../page_components";
import { Banner } from "./banner";
import { HowItWorks, LandingService, WhyLorcan, WhyLorcanSummary } from "./components";

import "./Landing.scss";



export const Landing = () => {
    const {navigate} = useAwNavigate();


    return (
        <div className="landing-pg">
            <Banner />

            <WhyLorcanSummary />
            <LandingService />
            <WhyLorcan />
            <HowItWorks />


            <ContentContainer style={{textAlign: 'center', lineHeight: '1.2'}}>
                <h3>
                    Explore a new era of corporate well-being. Join us in creating a workplace where every employee feels valued, supported, and motivated to contribute their best.
                </h3>
            </ContentContainer>


            <div style={{textAlign: "center", margin: '2rem 0'}}>
                <button className="accent" onClick={() => navigate(menuData.contact.route)}>
                    <span>Request Demo</span>
                </button>
            </div>


           
        </div>
    )
}
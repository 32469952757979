// import bg from "../../assets/images/resources.jpg";
import bg from "../../assets/images/wk-lf-balance.jpg";
import { ImageBackground } from "../../components/helpers";
import { ContentContainer } from "../page_components";

import "./OurServices.scss";
import { useNavigate } from "react-router-dom";
import { menuDetails } from "../../components/menus";
import { ListOfServices } from "./allServices";
import { shortenString } from "../../utils";





export const OurServices = () => {
    const maxLength = 200;
    
    return (
        <div className="ourservices">
            <ImageBackground bgImg={bg}>
                <ContentContainer>
                    <h1 style={{letterSpacing: '0.1rem'}}>Our Services</h1>
                </ContentContainer>
            </ImageBackground>
            <ContentContainer>
                <div className="service-list">
                    {ListOfServices.map((service, idx) => {
                        const linkUrl = menuDetails.serviceLink(service.title);
                        return (
                            <section key={idx} className="service">
                                <div className="image">
                                    <img src={service.img} />
                                    <div className="content" dangerouslySetInnerHTML={{ __html: shortenString(service.summary, maxLength)}}/>
                                    {/* <div className="content">
                                        {shortenString(service.summary, maxLength)}
                                    </div> */}
                                </div>
                                <div className="content">
                                    <h2>{service.title}</h2>
                                    <div>
                                        {(!!service.examples || !!service.page || service.summary.length > maxLength) && <MoreBtn url={linkUrl} />}
                                    </div>
                                </div>
                            </section>
                        )
                    })}
                </div>
            </ContentContainer>
        </div>
    )
}


const MoreBtn = ({...props}) => {
    const navigate = useNavigate();
    return (
        <button className="more-btn" type="button" onClick={(e:any) => {if(e) e.preventDefault(); navigate(props.url);}} ><span>{props.label ?? 'More'}</span></button>
    )
}